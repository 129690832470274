/*
 * EdgeIcon Functional Component
 * This component displays the SVG for the EdgeIcon
 */

import React from 'react';
import styled from 'styled-components';

const StyledEdgeIcon = styled.svg`
  max-width: 60px;
  width: 100%;
  max-height: 50px;
  fill: #0078D7;
`;

const edgeIcon = ( props ) => {
  return(
    <StyledEdgeIcon viewBox="0 0 60 64">
      <path d="m18.87 38c0 1.54 0.23 2.94 0.7 4.2 0.5 1.24 1.15 2.35 1.98 3.32s1.8 1.8 2.94 2.5c1.1 0.7 2.3 1.28 3.58 1.73 1.27 0.46 2.6 0.8 3.95 1.02 1.37 0.22 2.7 0.33 4.05 0.33 1.7 0 3.28-0.13 4.78-0.4 1.5-0.28 2.96-0.65 4.4-1.12s2.83-1.03 4.22-1.67c1.4-0.64 2.82-1.34 4.28-2.12v13.56c-1.63 0.8-3.23 1.47-4.83 2.05-1.6 0.56-3.2 1.05-4.83 1.44-1.63 0.4-3.28 0.7-4.97 0.88s-3.42 0.28-5.22 0.28c-2.4 0-4.72-0.28-6.95-0.83s-4.32-1.34-6.28-2.38-3.75-2.3-5.38-3.78c-1.64-1.48-3.03-3.15-4.2-5s-2.07-3.88-2.72-6.06c-0.63-2.18-0.95-4.5-0.95-6.96 0-2.63 0.36-5.13 1.08-7.52 0.73-2.4 1.76-4.58 3.12-6.58 1.35-2 3-3.78 4.95-5.33s4.14-2.82 6.58-3.8c-1.33 1.33-2.37 2.9-3.1 4.73-0.75 1.82-1.22 3.65-1.43 5.48h22.78c0-2.3-0.23-4.3-0.7-6.02s-1.22-3.15-2.27-4.28c-1.04-1.14-2.38-2-4.03-2.56-1.65-0.57-3.64-0.86-5.97-0.86-2.75 0-5.5 0.4-8.25 1.23-2.75 0.8-5.36 1.95-7.84 3.4-2.48 1.47-4.76 3.2-6.84 5.18-2.08 2-3.83 4.15-5.25 6.48 0.3-2.7 0.9-5.3 1.73-7.77s1.93-4.77 3.25-6.9c1.32-2.1 2.87-4.02 4.64-5.74s3.73-3.2 5.9-4.4 4.47-2.17 6.97-2.82c2.5-0.57 5.16-0.91 7.96-0.91 1.64 0 3.27 0.15 4.9 0.44 1.63 0.3 3.22 0.7 4.77 1.22 3.08 1.06 5.83 2.54 8.25 4.42 2.42 1.9 4.45 4.06 6.1 6.53s2.9 5.2 3.76 8.17 1.3 6.06 1.3 9.27v7.95h-40.91z" />
    </StyledEdgeIcon>
  );
}

export default edgeIcon;
